* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

ul,
li,
a {
  color: unset;
  list-style: none;
  text-decoration: none;
}

body {
  font-family: "Avenir Next LT Pro", sans-serif;
  font-weight: 400;
  overflow-x: hidden;
}

.span {
  color: var(--color-blue);
}

.section__padding {
  padding: 2rem 4rem;
}

.section__margin {
  margin: 4rem 6rem;
}

@media screen and (max-width: 850px) {
  .section__padding {
    padding: 2rem;
  }

  .section__margin {
    margin: 2rem;
  }
}

@media screen and (max-width: 650px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }
}

@media screen and (max-width: 450px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }
}

@media screen and (max-width: 350px) {
  .section__padding {
    padding: 1rem;
  }
}


.verifyDiv{
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
  height: 100vh;
  width: 100%;
  padding-top: 60px;
}

.verifyDiv p{
  font-size: 60px;
  color: rgb(4, 149, 194);
}

.verifyDiv h2{
  padding: 10px 0;
}

.verifyDiv h3{
  font-weight: 300;
}