@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --font-family: "Poppins", sans-serif;
  --font-base: "Avenir Next LT Pro";

  --color-blue: #00c0ea;
  --color-black: #0f0f0f;
  --color-navy: #00262f;
  --color-white: #fcfcfc;
}

@font-face {
  font-family: "Avenir Next LT Pro";
  src: url("./assests/font/AvenirNextLTPro-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Next LT Pro";
  src: url("./assests/font/AvenirNextLTPro-Demi.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Next LT Pro";
  src: url("./assests/font/AvenirNextLTPro-Demi.woff") format("woff");
  font-weight: 600; /* Demi */
  font-style: normal;
}

@font-face {
  font-family: "Avenir Next LT Pro";
  src: url("./assests/font/AvenirNextLTPro-Bold.woff") format("woff");
  font-weight: 700; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: "Avenir Next LT Pro";
  src: url("./assests/font/AvenirNextLTPro-HeavyCn.woff") format("woff");
  font-weight: 800; /* Heavy */
  font-style: normal;
}
