.waitlist {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.waitlist h1 {
    font-family: var(--font-family);
    font-size: 40px;
    font-weight: 600;
    line-height: 52px;
    text-transform: capitalize;
    margin-bottom: 1rem;
}

.waitlist p {
    font-family: var(--font-family);
    font-size: 22px;
    font-weight: 300;
    line-height: 40px;
    text-transform: capitalize;
    text-align: center;
    margin-bottom: 2rem;
}

.waitlist input {
    padding: 15px;
    margin-bottom: 1rem;
    width: 600px;
    border-radius: 5px;
    border: 1px solid #aaa;
}

.waitlist button {
    border-radius: 50px;
    margin-top: 1rem;
    padding: 20px 100px;
    color: var(--color-white);
    background-color: var(--color-blue);
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    cursor: pointer;
    gap: 10px;
    border: none;
}

.waitlist button:hover {
    color: var(--color-blue);
    background-color: var(--color-white);
    border: 2px solid var(--color-blue);
}

@media screen and (max-width: 769px) {
    .waitlist h1 {
        font-size: 30px;
        line-height: 45px;
        margin-bottom: .7rem;
    }
    .waitlist p {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 2rem;
    }
    .waitlist input {
        padding: 15px;
        margin-bottom: 1rem;
        width: 100%;
        border-radius: 5px;
        border: 1px solid #aaa;
    }
    .waitlist button {
        padding: 15px 70px;
    }
}

@media screen and (max-width: 376px) {
    .waitlist {
        margin-top: 2rem;
    }
    .waitlist h1 {
        font-size: 25px;
        line-height: 45px;
        margin-bottom: .7rem;
    }
    .waitlist p {
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 1.5rem;
    }
    .waitlist button {
        margin-top: .5rem;
        padding: 15px 60px;
    }
}