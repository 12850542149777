.small__section {
  margin: 5rem 10rem;
}

.testimonials__head {
  text-align: center;
  margin-bottom: 3rem;

  h2 {
    font-family: var(--font-family);
    font-size: 33px;
    font-weight: 600;
    line-height: 40px;
    color: var(--color-black);
    margin-bottom: 1rem;
  }
  p {
    color: #555555;
    font-family: var(--font-family);
    font-size: 20px;    
    font-weight: 400;
    line-height: 35px;
    text-align: center;
    width: 600px;
  }
}

.container {  
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 3rem;
    border: 1px solid var(--color-black);
    padding: 5rem;
    border-radius: 20px;
}

.testimonial {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.testimonial__item {

  @media screen and (min-width: 2000px) {
    min-height: 450px;
  }

  @media screen and (max-width: 850px) {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.testimonial__content {
  flex: 1;
  height: 100%;

  padding: 0 2rem;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  p {
    font-family: var(--font-family);
    font-size: 1.25rem;
    line-height: 2rem;
    color: #555;
    width: 500px;

    @media screen and (min-width: 2000px) {
      font-size: 1.75rem;
    }
  }

  h4 {
    font-family: var(--font-family);
    color: var(--color-blue);
    margin-top: 2rem;
    font-size: 28px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
  }

  h5 {
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 35px;
    text-align: center;
    font-weight: 400;
    color: #555555;
    margin-top: 5px;
  }
}

.testimonial__btns {
  display: flex;
  flex-direction: row;
  margin-top: 3rem;

  div {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 60px;
      height: 60px;
      padding: 10px;
      color: var(--color-blue);
      margin: .5rem;
    }

    &:hover {
      background-color: var(--color-blue);

      svg {
        color: var(--color-white);
      }
    }
  }
}


@media only screen and (min-width: 320px) and (max-width: 768px) {
  .small__section {
    margin: 0rem 2rem;
  }
  .container {
    margin-top: 0;
    padding: 0 2rem;
    border: none;
  }
  .testimonial {
    width: 150%;
    border: 1px solid var(--color-black);
    border-radius: 15px;
    padding: 1rem;
  }
  .testimonials__head {
    margin-bottom: 1rem;
  }
  .testimonial__content {
    flex: 1;
    height: 100%;
    width: 100%;

    padding: 1rem;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-size: 0.7rem;
      line-height: 1rem;
      color: #555555;
      font-family: var(--font-family);
      width: 220px;
    }

    h4 {
      font-size: 18px;
      font-weight: 600;
      color: var(--color-blue);
      margin-top: .5rem;
    }

    h5 {
      font-size: 16px;
      font-weight: 400;
      color: #555555;
      margin-top: 5px;
    }
  }

  .testimonials__head {
    h2 {
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      margin-bottom: 1rem;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      width: 200px;
      margin-bottom: 1rem;
    }
  }

  .testimonial__btns {
    margin-top: 0;
    div {
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    
      svg {
        width: 40px;
        height: 40px;
        padding: 5px;
        color: var(--color-blue);
        margin: 0;
      }
  
      &:hover {
        background-color: var(--color-blue);
  
        svg {
          color: var(--color-white);
        }
      }
    }
  }
}
@media only screen and (min-width: 769px) and (max-width: 1450px) {
  .small__section {
    margin: 0rem 2rem;
  }
  .container {
    margin-top: 0;
    padding: 2rem 2rem;
  }
  .testimonial__content {
    flex: 1;
    height: 100%;

    padding: 0 1rem;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-size: 0.8rem;
      line-height: 1.5rem;
      color: #555555;
      font-family: var(--font-family);
      width: 300px;
    }

    h4 {
      font-size: 24px;
      font-weight: 600;
      color: var(--color-blue);
      margin-top: 1rem;
    }

    h5 {
      font-weight: 400;
      color: #555555;
      margin-top: 5px;
    }
  }
  .testimonials__head {
    h2 {
      font-size: 28px;
      font-weight: 600;
      line-height: 35px;
      margin-bottom: 1rem;
    }
    
    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      text-align: center;
      width: 350px;
    }
  }
  .testimonial__btns {
    margin-top: 1rem;
  }
}
