.parent__head {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.parent__head-left {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  width: 100%;
}
.parent__head-left img {
  width: 90px;
  border-radius: 50%;
  object-fit: cover;
  background-color: #00c0ea;
}
.parent__head-left h2 {
  font-size: 1.5rem;
}
.parent__head-left p {
  font-family: "Avenir Next LT Pro", sans-serif;
  font-weight: 400;
  color: #555555;
}
.parent__head-right {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  width: 100%;
}
.parent__head-right button {
  font-family: "Avenir Next LT Pro", sans-serif;
  font-weight: 500;
  padding: 1rem 3rem;
  border-radius: 50px;
  border: none;
  cursor: pointer;
}
.parent__head-right button:first-child {
  background-color: #00c0ea;
  color: #fff;
  border: 1px solid #00c0ea;
  transition: 0.3s ease-in-out;
}
.parent__head-right button:first-child:hover {
  background-color: transparent;
  color: #00c0ea;
}
.parent__head-right button:last-child {
  background-color: transparent;
  border: 1px solid #e9240a;
  color: #e9240a;
  transition: 0.3s ease-in-out;
}
.parent__head-right button:last-child:hover {
  background-color: #e9240a;
  color: #fff;
}

.parent__bio {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  margin-top: 1rem;
}
.parent__bio h2 {
  font-size: 1.1rem;
  font-weight: 600;
}
.parent__bio p {
  line-height: 2.3rem;
}
@media screen and (max-width: 322px) {
  .parent__head {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
  }
  .parent__head-left img {
    width: 80px;
    border-radius: 50%;
    object-fit: cover;
    background-color: #00c0ea;
  }
  .parent__head-left h2 {
    font-size: 1.3rem;
  }
  .parent__head-left p {
    font-family: "Avenir Next LT Pro", sans-serif;
    font-weight: 400;
    font-size: 0.9rem;
    color: #555555;
  }
  .parent__bio p {
    line-height: 2rem;
    font-size: 0.9rem;
  }
  .parent__head-right button {
    font-family: "Avenir Next LT Pro", sans-serif;
    font-weight: 500;
    padding: 0.9rem 3rem;
    border-radius: 50px;
    border: none;
    cursor: pointer;
  }
}

@media screen and (min-width: 323px) and (max-width: 376px) {
  .parent__head {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
  }
  .parent__head-left img {
    width: 80px;
    border-radius: 50%;
    object-fit: cover;
    background-color: #00c0ea;
  }
  .parent__head-left h2 {
    font-size: 1.3rem;
  }
  .parent__head-left p {
    font-family: "Avenir Next LT Pro", sans-serif;
    font-weight: 400;
    font-size: 0.9rem;
    color: #555555;
  }
  .parent__bio p {
    line-height: 2rem;
    font-size: 0.9rem;
  }
  .parent__head-right button {
    font-family: "Avenir Next LT Pro", sans-serif;
    font-weight: 500;
    padding: 0.9rem 3rem;
    border-radius: 50px;
    border: none;
    cursor: pointer;
  }
}

@media screen and (min-width: 377px) and (max-width: 426px) {
  .parent__head {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
  }
}
