.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 7rem;
    position: fixed;
    width: 100%;
    background-color: white;
    z-index: 50;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.navbar-links_logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
}

.navbar-links_container {
    display: flex;
    justify-content: right;
    align-self: center;
}

.navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
}


.navbar-links_logo img {
    width: 80px;
    padding: 10px;
}

.navbar-links li {
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin: 0 1rem;
}

.navbar-links li a:hover {
    color: var(--color-blue);
}


.navbar-download {
    margin: .5rem;
    margin-right: 2rem;
}

.navbar-download button {
    border-radius:50px;
    padding: 10px 15px;
    border: 1.5px solid var(--color-blue);
    color: var(--color-blue);
    font-family: var(--font-family);
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;
    gap: 10px;
    background: var(--color-white);
}

.navbar-download button:hover {
    background-color: var(--color-blue);
    color: var(--color-white);
}

.navbar-menu {
    justify-content: center;
    align-items: center;
    display: none;
}

.navbar-menu svg {
    margin-right: 1rem;
}

.navbar-menu_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background: var(--color-blue);
    color: white !important;
    transition: 0.5s ease;
    flex-direction: column;
    z-index: 100;
}

.navbar-menu_container .container_close {
    font-size: 24px;
    color: var(--color-white);
    cursor: pointer;
    transition: 0.5s ease;
    position: absolute;
    top: 20px;
    right: 20px;
}

.navbar-menu_container-links {
    margin: 6rem;
    list-style: none;
}

.navbar-menu-links {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.navbar-menu-links li {
    margin-bottom: 1.5rem;
    cursor: pointer;
    /* color: var(--color-navy); */
    font-size: 2rem;
    text-align: center;
    font-family: var(--font-family);
    font-weight: 500;
}

.navbar-menu-links li:hover {
    color: var(--color-white);
}

.navbar-menu-download {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}



/* $small-One : ;
$small-Two : ;
$medium-One : 481px ;
$medium-Two : 768px;
$large-One : 1025px;
$large-Two : 1200px; */


@media only screen and (min-width: 320px) and (max-width: 768px) {
    .navbar {
        display: flex;
        font-size: 13px;
        align-items: center;
        justify-content: space-between;
        padding: 8px 5px;
        position: fixed;
        width: 100%;
        background-color: white;
        z-index: 50;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }

    .navbar-links li {
        font-family: var(--font-family);
        font-size: 12px;
        font-weight: 400;
        line-height: 28px;
        margin: 0 1rem;
    }


    .navbar-links_logo img {
        width: 70px;
        padding: 10px;
    }

    
}

