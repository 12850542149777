.service{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 15px;
    padding: 50px 5rem;
    padding-top: 80px;
}

.first-role {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
    width: 100%;
}

.second-role {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    width: 100%;
}

.service-box1 {
    background-color: var(--color-navy);
    border-radius: 15px;
    padding: 30px;
    position: relative;
    width: 100%;
}

.service-box2 {
    background: var(--color-blue);
    border-radius: 15px;
    padding: 30px;
    position: relative;
    width: 100%;
}

.service-box3 {
    background-color: var(--color-navy);
    border-radius: 15px;
    padding: 30px;
    position: relative;
    width: 100%;
}

.service-box4 {
    background: var(--color-blue);
    border-radius: 15px;
    padding: 30px;
    position: relative;
    width: 100%;
}

.service-box5 {
    background-color: var(--color-navy);
    border-radius: 15px;
    padding: 30px;
    position: relative;
    width: 100%;
}

.service-box1 h2,
.service-box2 h2,
.service-box3 h2,
.service-box4 h2,
.service-box5 h2 {
    font-family: var(--font-family);
    color: var(--color-white);
    font-size: 30px;
    font-weight: 700;
    line-height: 48px;
}

.service-box1 p, 
.service-box2 p,
.service-box3 p,
.service-box4 p,
.service-box5 p {
    font-family: var(--font-family);
    color: var(--color-white);
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 3rem;
    z-index: 3;
    width: 320px;
}


.service-box1 img,
.service-box2 img,
.service-box3 img,
.service-box4 img,
.service-box5 img {
    width: 121px;
    height: 132px;
    position: absolute;
    z-index: 1;
    top: 55%;
    left: 65%;
    opacity: 0.3;
}

.service-box4 img,
.service-box5 img {
    top: 55%;
    left: 78%;
}

@media only screen and (min-width: 769px) and (max-width: 1450px) {
    .service{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 15px;
        padding: 50px 20px;
        padding-top: 80px;
    }

    .service-box1,
    .service-box2,
    .service-box3,
    .service-box4,
    .service-box5 {
        padding: 28px;
    }
    .service-box1 h2,
    .service-box2 h2,
    .service-box3 h2,
    .service-box4 h2,
    .service-box5 h2 {
        font-size: 22px;
        line-height: 35px;
        margin-bottom: .5rem;
    }
    .service-box1 p, 
    .service-box2 p,
    .service-box3 p,
    .service-box4 p,
    .service-box5 p {
        font-size: 14px;
        line-height: 23px;
        width: 230px;
        margin-bottom: 0rem;
    }
    .service-box1 img,
    .service-box2 img,
    .service-box3 img,
    .service-box4 img,
    .service-box5 img {
        top: 50%;
        left: 60%;
        width: 100px;
        height: 110px;
    }
    .service-box4 img,
    .service-box5 img {
        top: 47%;
        left: 70%;
    }
}

@media screen and (min-width: 427px) and (max-width: 850px) {

    .service{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 15px;
        padding: 50px 10px;
        padding-top: 80px;
    }


    .first-role {
        grid-template-columns: 1fr;
    }
    .second-role {
        grid-template-columns: 1fr;
    }
    .service-box1 h2,
    .service-box2 h2,
    .service-box3 h2,
    .service-box4 h2,
    .service-box5 h2 {
        font-size: 19px;
        line-height: 35px;
        margin-bottom: .5rem;
    }
    .service-box1 p, 
    .service-box2 p,
    .service-box3 p,
    .service-box4 p,
    .service-box5 p {
        font-size: 14px;
        line-height: 23px;
        width: 300px;
        margin-bottom: 1rem;
    }
    .service-box1 img,
    .service-box2 img,
    .service-box3 img,
    .service-box4 img,
    .service-box5 img {
        top: 48%;
        left: 70%;
        width: 85px;
        height: 95px;
    }
    .service-box4 img {
        top: 43%;
        left: 70%;
    }
    .service-box5 img {
        top: 47%;
        left: 70%;
    }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {

    .service{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 15px;
        padding: 50px 10px;
        padding-top: 80px;
    }


    .first-role {
        grid-template-columns: 1fr;
    }
    .second-role {
        grid-template-columns: 1fr;
    }
    .service-box1 h2,
    .service-box2 h2,
    .service-box3 h2,
    .service-box4 h2,
    .service-box5 h2 {
        font-size: 17px;
        margin-bottom: .5rem;
    }
    .service-box1 p, 
    .service-box2 p,
    .service-box3 p,
    .service-box4 p,
    .service-box5 p {
        width: 260px;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 1rem;
    }
    .service-box1 img,
    .service-box2 img,
    .service-box3 img,
    .service-box4 img,
    .service-box5 img {
        top: 47%;
        left: 70%;
        width: 85px;
        height: 95px;
    }
    .service-box4 img {
        top: 47%;
        left: 70%;
    }
    .service-box5 img {
        top: 47%;
        left: 70%;
    }
}
