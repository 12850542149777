.header {
    padding: 220px 8rem;
    padding-bottom: 100px;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.header-text h1 {
    font-family: var(--font-family);
    font-size: 60px;
    font-weight: 700;
}

.header-text p {
    color: var(--color-black);
    font-family: var(--font-family);
    font-size: 24px;
    font-weight: 400;
    padding: 10px 0;
}

.header-text button {
    border-radius:50px;
    padding: 15px 20px;
    color: var(--color-white);
    background-color: var(--color-blue);
    font-family: var(--font-family);
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    border: none;
    margin-top: 10px;
}

.header-text button:hover {
    color: var(--color-blue);
    background-color: var(--color-white);
    border: 2px solid var(--color-blue);
}



.header-bar {
    background: var(--color-blue);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-bar p {
    font-family: var(--font-family);
    color: var(--color-white);
    font-size: 35px;
    font-weight: 600;
    padding: 30px 0;
}


/* $small-One : ;
$small-Two : ;
$medium-One : 481px ;
$medium-Two : 768px;
$large-One : 1025px;
$large-Two : 1200px; */

.break{
    display: block;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {

    .header {
        padding: 150px 20px;
    }

    .header-content {
        display: block;
    }

    .header-text h1 {
        font-family: var(--font-family);
        font-size: 35px;
        font-weight: 600;
    }

    .break{
        display: none;
    }

    .header-text{
        width: 100%;
    }

    .header-text p {
        font-family: var(--font-family);
        font-size: 15px;
        font-weight: 300;
    }

    .header-text button {
        font-family: var(--font-family);
        font-size: 12px;
        font-weight: 400;
        padding: 10px 15px;
    }

    
    .header-img img{
        width: 100%;
        margin-top: 30px;
    }

    
    
}


@media only screen and (min-width: 769px) and (max-width: 1450px) {
    .header {
        padding: 150px 40px;
    }

    .break{
        display: none;
    }

    .header-text{
        width: 100%;
    }

    .header-text h1 {
        font-family: var(--font-family);
        font-size: 40px;
        font-weight: 600;
    }

    .header-text p {
        font-family: var(--font-family);
        font-size: 15px;
        font-weight: 300;
    }
}