.howitworks {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 5rem;
    padding-top: 6rem;
}

.howitworks-img {
    margin-right: 7rem;
}

.howitworks-img img {
    width: 700px;
}

.howitworks-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.hit-header h2 {
    font-family: var(--font-family);
    font-size: 40px;
    font-weight: 600;
    line-height: 45px;
    margin-bottom: 5rem;
}

.hit-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    margin-bottom: 2rem;
    border-left: 3px solid var(--color-blue);
    border-radius: 10px;
}

.hit-content svg {
    margin-right: 2rem;
    color: var(--color-blue);
}

.hit-text h3 {
    font-family: var(--font-family);
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 1rem;
} 

.hit-text p {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}

@media only screen and (min-width: 769px) and (max-width: 1450px) {
    .howitworks {
        padding-top: 7rem;
    }
    .howitworks-img {
        margin-right: 5rem;
    }

    .howitworks-img img {
        width: 400px;
    }
    .hit-header h2 {
        font-size: 28px;
        line-height: 30px;
        margin-bottom: 1.5rem;
    }
    .hit-content {
        padding: 0 .5rem;
        margin-bottom: 1rem;
    }
    .hit-content svg {
        width: 80px;
        margin-right: 1rem;
    }
    .hit-text h3 {
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        margin-bottom: .5rem;
    } 

    .hit-text p {
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
    }

    .howitworks {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 20px;
        padding-top: 6rem;
    }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
    .howitworks {
        padding-top: 7rem;
        flex-direction: column;
    }
    .hit-content {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border: none;
        margin-bottom: 0;
    }
    .hit-content svg {
        width: 40px;
        height: 40px;
        padding: 10px;
        background: var(--color-blue);
        border-radius: 50%;
        color: var(--color-white);
        margin-bottom: 1rem;
    }
    .howitworks-img {
        margin-right: 0;
        margin-bottom: 5rem;
    }
    .howitworks-img img {
        width: 100%;
    }
    .hit-text {
        margin-bottom: .5rem;
    }

    .howitworks {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 10px;
        padding-top: 6rem;
    }

    .hit-header h2 {
        font-size: 25px;
        margin-bottom: 30px;
    }
}
