.login {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #fcfcfc;
  width: 100vw;
  height: 100vh;
}

.login__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  width: 35%;
}
.login__container img {
  width: 100px;
}
.login__container div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
.login__container div h1 {
  font-size: 2.8rem;
  font-weight: 700;
}
.login__container div p {
  font-weight: 400;
  font-size: 1.1rem;
  color: #555555;
}
.login__container form {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: 100%;
  margin-top: 1rem;
}
.login__container form input {
  color: #8f8f8f;
  font-family: "Avenir Next LT Pro", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid #d8d8d8;
  width: 100%;
  background-color: #fcfcfc;
}
.login__container form input::placeholder {
  font-family: "Avenir Next LT Pro", sans-serif;
  font-size: 0.9rem;
  color: #8f8f8f;
}
.login__container form input:focus {
  outline-color: #d0d0d0;
  outline-width: 1px;
}
.login__container form div {
  position: relative;
  width: 100%;
}
#visible {
  position: absolute;
  right: 5%;
  top: 30%;

  width: 24px;
  height: 24px;
  fill: #b1b1b1;
  cursor: pointer;
}
.login__container form button {
  font-family: "Avenir Next LT Pro", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
  padding: 1rem;
  border: none;
  color: #fff;
  background-color: #00c0ea;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.login__container form button:hover {
  background-color: #008faf;
}

@media screen and (max-width: 322px) {
  .login__container {
    width: 90%;
  }
  .login__container div h1 {
    font-size: 2.1rem;
  }
  .login__container div p {
    font-weight: normal;
    font-size: 1rem;
    color: #555555;
  }
}

@media screen and (min-width: 323px) and (max-width: 376px) {
  .login__container {
    width: 90%;
  }
  .login__container div h1 {
    font-size: 2.3rem;
  }
  .login__container div p {
    font-weight: normal;
    font-size: 1rem;
    color: #555555;
  }
}

@media screen and (min-width: 377px) and (max-width: 426px) {
  .login__container {
    width: 90%;
  }
  .login__container div h1 {
    font-size: 2.3rem;
  }
  .login__container div p {
    font-weight: normal;
    font-size: 1rem;
    color: #555555;
  }
}

@media screen and (min-width: 427px) and (max-width: 769px) {
  .login__container {
    width: 65%;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .login__container {
    width: 45%;
  }
  .login__container div h1 {
    font-size: 2.6rem;
  }
  .login__container div p {
    font-weight: normal;
    font-size: 1.05rem;
    color: #555555;
  }
}
