.privacy-container {
  padding: 10rem 0;
}
.privacy-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
}
.privacy-header h1 {
  font-size: 4.5rem;
  font-weight: 500;
}
.privacy-header p {
  font-weight: 600;
  font-size: 0.9rem;
}

.privacy-body {
  display: flex;
  gap: 2rem;
}

.privacy-body-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.privacy-body-text-head {
  font-weight: 500;
  font-size: 1.8rem;
  width: 90%;
}
.privacy-body-text-policy {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.privacy-body-text-policy h3 {
  font-size: 1.3rem;
  font-weight: 500;
}
.privacy-body-text-policy p {
  line-height: 1.5rem;
  font-weight: 400;
  color: rgb(109, 109, 109);
}
.privacy-body-text-summary {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.privacy-body-text-summary h3 {
  font-size: 1.4rem;
  font-weight: 500;
}
.privacy-body-text-summary-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-left: 2rem;
}
.policy-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.policy-list h3 {
  font-size: 1.1rem;
  font-weight: 500;
}
.policy-list p {
  line-height: 1.5rem;
  font-weight: 400;
  color: #000;
  margin-left: 1rem;
}
.policy-list p span {
  font-weight: 500;
}
.policy-list ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.policy-list ul li {
  list-style: circle;
  margin-left: 3rem;
}

.privacy-body-table {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.privacy-body-table h3 {
  font-size: 1.3rem;
  font-weight: 500;
}
.privacy-body-table ol {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.privacy-body-table ol li {
  font-weight: 700;
  font-size: 0.9rem;
  text-decoration: underline;

  margin-left: 2rem;
  list-style: decimal;
  cursor: pointer;
}

@media screen and (max-width: 322px) {
  .privacy-header h1 {
    font-size: 3rem;
    font-weight: 500;
  }
  .privacy-body {
    display: flex;
    flex-direction: column-reverse;
    gap: 3rem;
  }

  .privacy-body-text-head {
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 1.5rem;
    width: 100%;
  }

  .privacy-body-text-policy p {
    line-height: 2rem;
    font-weight: 400;
    font-size: 0.9rem;
    color: rgb(109, 109, 109);
  }

  .privacy-body-text-summary-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-left: 1rem;
  }

  .policy-list p {
    line-height: 2rem;
    font-size: 0.9rem;
    font-weight: 400;
    color: #000;
    margin-left: 1rem;
  }
  .policy-list ul li {
    list-style: circle;
    margin-left: 3rem;
    font-size: 0.9rem;
  }

  .privacy-body-table {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .privacy-body-table ol li {
    font-weight: 700;
    font-size: 1rem;
    text-decoration: underline;

    margin-left: 2rem;
    list-style: decimal;
    cursor: pointer;
  }
}

@media screen and (min-width: 323px) and (max-width: 376px) {
  .privacy-header h1 {
    font-size: 3rem;
    font-weight: 500;
  }
  .privacy-body {
    display: flex;
    flex-direction: column-reverse;
    gap: 3rem;
  }

  .privacy-body-text-head {
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 1.5rem;
    width: 100%;
  }

  .privacy-body-text-policy p {
    line-height: 2rem;
    font-weight: 400;
    font-size: 0.9rem;
    color: rgb(109, 109, 109);
  }

  .privacy-body-text-summary-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-left: 1rem;
  }

  .policy-list p {
    line-height: 2rem;
    font-size: 0.9rem;
    font-weight: 400;
    color: #000;
    margin-left: 1rem;
  }
  .policy-list ul li {
    list-style: circle;
    margin-left: 3rem;
    font-size: 0.9rem;
  }

  .privacy-body-table {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .privacy-body-table ol li {
    font-weight: 700;
    font-size: 1rem;
    text-decoration: underline;

    margin-left: 2rem;
    list-style: decimal;
    cursor: pointer;
  }
}

@media screen and (min-width: 377px) and (max-width: 426px) {
  .privacy-header h1 {
    font-size: 3rem;
    font-weight: 500;
  }
  .privacy-body {
    display: flex;
    flex-direction: column-reverse;
    gap: 3rem;
  }

  .privacy-body-text-head {
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 2rem;
    width: 100%;
  }

  .privacy-body-text-summary-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-left: 1rem;
  }

  .privacy-body-table {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .privacy-body-table ol li {
    font-weight: 700;
    font-size: 1rem;
    text-decoration: underline;

    margin-left: 2rem;
    list-style: decimal;
    cursor: pointer;
  }
}

@media screen and (min-width: 427px) and (max-width: 769px) {
  .privacy-body-text-head {
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 2rem;
    width: 100%;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .privacy-body-text-head {
    font-weight: 500;
    font-size: 1.5rem;
    width: 100%;
  }
}
