.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3rem 5rem;
    position: relative;
}

.about-main {
    display: flex;
    gap: 40px;
}

.main-img {
    width: 100%;
}
.main-img img {
    width: 100%;
}


.main-text{
    width: 100%;
}

.main-text h2 {
    font-family: var(--font-family);
    font-size: 30px;
    font-weight: 600;
    color: var(--color-black);
}

.main-text p {
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 350;
    line-height: 50px;
    color: var(--color-black);
}

.main-text img:first-of-type {
    position: absolute;
    top: 50%;
    left: 48.5%;
    z-index: -1;
}

.main-text img:last-of-type {
    position: absolute;
    top: 57%;
    left: 86.3%;
}

.about-lower {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-top: 5rem;
}

.mission {
    border-radius: 10px;
    border: 4px solid var(--color-blue);
    padding: 50px 32px 45px;
}

.mission h1 {
    font-family: var(--font-family);
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    color: var(--color-blue);
    text-align: center;
    margin-bottom: 1.5rem;
}

.mission p, 
.vision p {
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 350;
    line-height: 32px;
    text-align: center;
}

.vision {
    border-radius: 10px;
    border: 4px solid var(--color-navy);
    padding: 50px;
}

.vision h1 {
    font-family: var(--font-family);
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    color: var(--color-navy);
    text-align: center;
    margin-bottom: 1.5rem;
}


@media only screen and (min-width: 320px) and (max-width: 768px) {
    .about {
        display: block;
        margin: 3rem 5px;
        position: relative;
    }


    .about-main {
        display: flex;
        flex-direction: column;
    }

    .main-img img {
        width: 100%;
    }


    .main-text h2 {
        font-family: var(--font-family);
        font-size: 25px;
        color: var(--color-black);
    }


    .main-text p{
        font-size: 15px;
        line-height: 20px;
        text-align: justify;
    }

    .about-lower{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .mission p, 
    .vision p {
        font-family: var(--font-family);
        font-size: 15px;
        font-weight: 350;
        line-height: 25px;
        text-align: center;
    }

    .mission {
        border-radius: 10px;
        border: 4px solid var(--color-blue);
        padding: 20px;
    }

    .vision {
        border-radius: 10px;
        border: 4px solid var(--color-blue);
        padding: 20px;
    }


    .header-bar p{
        text-align: center;
        font-size: 20px;
    }
}


@media only screen and (min-width: 769px) and (max-width: 1450px) {
    .about {
        display: block;
        margin: 3rem 20px;
        position: relative;
    }

    .main-text h2 {
        font-family: var(--font-family);
        font-size: 20px;
        color: var(--color-black);
    }

    .main-text p{
        font-size: 13px;
        line-height: 20px;
        text-align: justify;
    }

    .about-main {
        display: flex;
        flex-direction: row;
    }
    .mission {
        border-radius: 10px;
        border: 4px solid var(--color-blue);
        padding: 20px;
    }

    .vision {
        border-radius: 10px;
        border: 4px solid var(--color-blue);
        padding: 20px;
    }

    .mission p, 
    .vision p {
        font-family: var(--font-family);
        font-size: 13px;
        font-weight: 350;
        line-height: 25px;
        text-align: center;
    }

}